var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4"},[_c('v-row',{staticClass:"pl-2 pr-2 pt-2"},[_c('v-col',{attrs:{"cols":"","sm":"6","md":"5"}},[_c('v-text-field',{attrs:{"label":_vm.$t('flow.SearchTerm'),"outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchFlows($event)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-col',{attrs:{"cols":""}}),_c('v-col',{attrs:{"cols":"","sm":"2","md":"1"}},[_c('v-btn',{staticStyle:{"margin":"2px 0px 0px -10px","width":"100%"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push("/home/flow/designer")}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("flow.CreateBtn"))+" ")],1)],1)],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.isSkeletonLoading,"height":"600","type":"table"}},[_c('v-data-table',{staticClass:"work-list-id-page",staticStyle:{"margin-top":"-20px"},attrs:{"item-key":"name","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totals,"footer-props":_vm.tableFooterProps},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":_vm.searchFlows,"update:page":_vm.onChangePagination},scopedSlots:_vm._u([{key:"item.flowID",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.goToDesigner(item.flowID)}}},[_vm._v(_vm._s(item.flowID))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.goToDesigner(item.flowID)}}},[_vm._v(_vm._s(item.title))])]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block text-center fs12"},[_vm._v(" "+_vm._s(_vm.formatDate(item.dateCreated))+" - "+_vm._s(_vm.formatHour(item.dateCreated))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteFlow(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)})],1),_c('DialogConfirm',{ref:"DialogConfirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }