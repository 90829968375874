<template>
  <div class="pt-4">
    <v-row class="pl-2 pr-2 pt-2">
      <v-col cols sm="6" md="5">
        <v-text-field
          :label="$t('flow.SearchTerm')"
          v-model="searchTerm"
          outlined
          dense
          @keyup.enter="searchFlows"
        ></v-text-field>
      </v-col>
      <v-col cols> </v-col>
      <v-col cols sm="2" md="1">
        <v-btn
          @click="$router.push(`/home/flow/designer`)"
          color="primary"
          style="margin: 2px 0px 0px -10px; width: 100%"
        >
          <v-icon class="mr-1" small>mdi-plus</v-icon>
          {{ $t("flow.CreateBtn") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-skeleton-loader :loading="isSkeletonLoading" height="600" type="table">
      <v-data-table
        class="work-list-id-page"
        item-key="name"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totals"
        :footer-props="tableFooterProps"
        @update:items-per-page="searchFlows"
        @update:page="onChangePagination"
        style="margin-top: -20px"
      >
        <template v-slot:[`item.flowID`]="{ item }">
          <a @click="goToDesigner(item.flowID)">{{ item.flowID }}</a>
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <a @click="goToDesigner(item.flowID)">{{ item.title }}</a>
        </template>

        <template v-slot:[`item.dateCreated`]="{ item }">
          <span class="d-block text-center fs12">
            {{ formatDate(item.dateCreated) }} -
            {{ formatHour(item.dateCreated) }}
          </span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="deleteFlow(item)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-skeleton-loader>

    <DialogConfirm ref="DialogConfirm" />
  </div>
</template>

<script>
import { TABLE_FOOTER_PROPS } from "@/plugins/constant";
import moment from "moment";
import i18n from "@/plugins/i18n";
import DialogConfirm from "@/components/DialogConfirm";
import { searchFlows, deleteFlow } from "./services";

const HEADER_CONFIGS = [
  {
    text: i18n.t("flow.FlowID"),
    align: "start",
    sortable: false,
    value: "flowID",
  },
  {
    text: i18n.t("flow.Title"),
    align: "start",
    sortable: false,
    value: "title",
  },
  {
    text: i18n.t("flow.Description"),
    align: "start",
    sortable: false,
    value: "description",
  },
  {
    text: i18n.t("flow.DateCreated"),
    align: "center",
    sortable: false,
    value: "dateCreated",
    width: "180px",
  },
  {
    text: i18n.t("flow.Actions"),
    align: "center",
    sortable: false,
    value: "actions",
    width: "150px",
  },
];
export default {
  components: {
    DialogConfirm,
  },
  data() {
    return {
      isSkeletonLoading: true,
      searchTerm: "",
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      totals: 0,
      items: [],
      headers: HEADER_CONFIGS,
      tableFooterProps: TABLE_FOOTER_PROPS,
    };
  },
  async mounted() {
    this.searchFlows();
  },
  methods: {
    formatDate(str) {
      return str ? moment(str).format("DD-MM-YYYY") : "";
    },
    formatHour(str) {
      return str ? moment(str).format("HH:mm") : "";
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    goToDesigner(flowID) {
      this.$router.push(`/home/flow/designer?flowID=${flowID}`);
    },
    async deleteFlow({ flowID }) {
      if (!(await this.$refs.DialogConfirm.confirm())) return;
      await deleteFlow(flowID);
      this.searchFlows();
    },
    async searchFlows() {
      let { page, itemsPerPage } = this.options;

      this.isSkeletonLoading = true;
      let apiResult = await searchFlows(this.searchTerm, page, itemsPerPage);
      this.isSkeletonLoading = false;
      if (apiResult.error) {
        return this.$toast.error(
          "Cannot get list flow schema at the moment. Please try again later!"
        );
      }
      var { items, totals } = apiResult;
      this.totals = totals;
      this.items = items;
    },
    async onChangePagination(value) {
      this.options.page = value;
      await this.searchFlows();
    },
  },
};
</script>